<template>
  <div>
    <b-card>
      <!-- Other stuff -->
      <b-overlay :show="show">
        <b-form @submit="submit">
          <b-row no-gutters>
            <b-col cols="8">
              <b-form-group
                label="Url hiển thị"
                label-for="h-first-name"
                label-cols-md="3"
              >
                <b-form-input
                  ref="mylink"
                  :value="url + Setting.token"
                  type="text"
                  rel="noopener noreferrer"
                  placeholder="Token donate alert address"
                />
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-button
                variant="primary"
                @click="copyURL"
              ><feather-icon icon="CopyIcon" size="12" />
              Copy</b-button>
              <b-button
                variant="danger"
                @click="generationURL"
              ><b-spinner
                v-if="loading_generate"
                small
              /> <feather-icon icon="RefreshCwIcon" size="12" />
              đổi url mới</b-button>
            </b-col>
          </b-row>
          <b-row no-gutters>
            <b-col cols="8">
              <b-form-group
                label="Tiêu đề"
                label-for="h-first-name"
                label-cols-md="3"
              >
                <b-form-input
                  v-model="Setting.json.title"
                  type="text"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row no-gutters>
            <b-col cols="8">
              <b-form-group
                label="Mục tiêu"
                label-for="h-first-name"
                label-cols-md="3"
              >
                <b-form-input
                  v-model="Setting.json.max"
                  type="number"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row no-gutters>
            <b-col cols="12">
              <b-form-group
                label="Không hiển thị khi đạt mục tiêu"
                label-cols-md="2"
              >
                <b-form-checkbox
                  name="check-button"
                  v-model="Setting.json.hidden_done"
                  switch
                  inline
                  :value="true"
                  :unchecked-value="false"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-button
                type="submit"
                variant="success"
              >
                <b-spinner
                  small
                  v-if="loading_submit"
                /> Cập nhật</b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-overlay>
    </b-card>
  </div>
</template>
<script>
import { BCol, BRow, BForm, BFormCheckbox, BFormGroup, BFormInput, BSpinner, BCard, BOverlay, BButton } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BFormCheckbox,
    BCol,
    BRow,
    BForm,
    BFormInput,
    BButton,
    BFormGroup,
    BSpinner,
    BCard,
    BOverlay,
  },
  data() {
    return {
      loading_submit: false,
      show: true,
      loading_generate: false,
      status: true,
      url: `${process.env.VUE_APP_HOME_URL}/screens/target-progress/`,
      id: null,
    }
  },
  mounted() {
    this.show = true
    if (this.Setting.id === null) {
      useJwt.getWidget('target')
        .then(({ data }) => {
          this.$store.commit('donate/GET_TARGET', data)
          this.show = false
        }).catch(() => {
          // 404
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error!',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        })
    } else {
      this.show = false
    }
    
  },
  computed: {
    Setting() {
      return this.$store.getters['donate/targetData']
    },
  },
  methods: {
    submit(e) {
      e.preventDefault()
      this.loading_submit = true
      useJwt.updateWidget('target', this.Setting.json)
        .then(() => {
          this.$store.commit('donate/UPDATE_TARGET', this.Setting.json)
          this.loading_submit = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Update success!',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
        })
    },
    copyURL() {
      this.$refs.mylink.select()
      document.execCommand('copy')
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Text copied',
          icon: 'BellIcon',
        },
      })
    },
    generationURL(e) {
      e.preventDefault()
      this.loading_generate = true
      useJwt.generatorWidgetToken('target').then(({ data }) => {
        this.loading_generate = false
        if (data.result === true) {
          this.$store.commit('donate/GENERATOR_TOKEN', {
            type: 'target',
            payload: data.data,
          })
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Generated token success!',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: data.error,
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      })
    },
  },
}
</script>
